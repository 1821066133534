import HighlightListMui from '../vendor-list-highlights-mui/HighlightListMui'
import { VendorSearchState } from '../states/vendor-list'
import { Stack } from '@mui/material'
import React from 'react'
import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { Coordinates } from '@/generated/graphql'

const vendorListHighlightsHighlightCategoryFragment = graphql(`
  fragment VendorListHighlights_HighlightCategoryFragment on VendorHighlightCategory {
    ...HighlightList_HighlightCategoryFragment
    id
    vendors(limit: 5) {
      ...HighlightList_VendorDataFragment
    }
  }
`)

type Props = {
  location: Coordinates
  search: VendorSearchState['search']
  highlightCategoryData: FragmentType<typeof vendorListHighlightsHighlightCategoryFragment>[]
}

const VendorListHighlights: React.FC<Props> = ({ search, highlightCategoryData, location }) => {
  const highlightCategories = useFragment(
    vendorListHighlightsHighlightCategoryFragment,
    highlightCategoryData,
  )

  const handleViewMoreClick = (tagName: string) => {
    search({ highlightsTags: [tagName] })
  }
  return (
    <Stack spacing={1} marginBottom={1} direction="column">
      {highlightCategories.map((highlight) => (
        <HighlightListMui
          location={location}
          vendorData={highlight.vendors}
          onViewMoreClick={(tagName) => handleViewMoreClick(tagName)}
          highlightCategoryData={highlight}
          key={highlight.id}
        />
      ))}
    </Stack>
  )
}

export default VendorListHighlights
