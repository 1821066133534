import type { NextPage } from 'next'
import { AppProps } from 'next/app'
import styled from 'styled-components'
import React from 'react'
import { useMedia } from 'react-use'
import { Box, Container, Grid as MuiGrid } from '@mui/material'
import { mobileMediaQuery } from '@caterdesk/ui--theme'

import VendorHeader from '@/components/page-specific/gm/components/vendor-header/overview'
import VendorSearchBar from '@/components/page-specific/gm/components/vendor-search-bar'
import VendorsList from '@/components/page-specific/gm/components/vendors-list'
import VendorFiltersMui from '@/components/page-specific/gm/components/vendor-filters/index-mui'
import { useVendorListPageState } from '@/components/page-specific/gm/components/states/useVendorListPageState'
import withGm from '@/components/page-specific/gm/components/withGm'
import getDomainLangPaths from '@/helpers/getDomainLangPaths'
import { useVendorSearchState } from '@/components/page-specific/gm/components/states/vendor-list'
import { useVendorHighlightCategories } from '@/components/page-specific/gm/components/states/useVendorHighlightCategories'
import VendorListHighlights from '@/components/page-specific/gm/components/vendor-list-highlights'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

const MainWrapper = styled.div`
  padding: 16px 8px;
  background-color: ${(p) => p.theme.colors.background_lightest};
  & > div {
    row-gap: 0;
  }

  @media ${mobileMediaQuery} {
    padding-top: 0;
  }
`

const Vendors: NextPage = () => {
  const showHighlightsFlagOn = useFeatureFlag(SPLITS.SHOW_HIGHLIGHTS_GM)

  const {
    called,
    filterOpen,
    toggleFilterOpen,
    search,
    filters,
    searching,
    stats,
    filterCount,
    defaultSearch,
    vendors,
    pageInfo,
  } = useVendorSearchState()
  const { data: highlightsData } = useVendorHighlightCategories()
  const { userBasketSettings, minAllowedDeliveryDate, deliveryTimeSlots } = useVendorListPageState()
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const showHighlights =
    showHighlightsFlagOn &&
    !filters.cuisineTags?.length &&
    !filters.dietaryTags?.length &&
    !filters.keyDatesTags?.length &&
    !filters.highlightsTags?.length &&
    !filters.occasionTags?.length &&
    !filters.diversityOfOwnershipTags?.length &&
    !filters.search &&
    highlightsData &&
    filters.location &&
    filters.supplierType !== 'pantry'

  return (
    <Container>
      {!isMobileScreen && <VendorHeader isPantryOnly={filters.supplierType === 'pantry'} />}

      <Box padding={1} sx={{ borderColor: 'divider' }} borderTop={1} borderBottom={1}>
        <VendorSearchBar
          called={called}
          filterCount={filterCount}
          filters={filters}
          search={search}
          toggleFilterOpen={toggleFilterOpen}
          userBasketSettings={userBasketSettings.value}
          minAllowedDate={minAllowedDeliveryDate}
          timeSlots={deliveryTimeSlots}
          onUserBasketSettingsChange={userBasketSettings.set}
        />
      </Box>
      <MainWrapper>
        <MuiGrid container>
          <MuiGrid item md={12} xs={12} paddingBottom={2}>
            {called ? (
              <VendorFiltersMui
                searching={searching}
                stats={stats}
                filters={filters}
                filterOpen={filterOpen}
                toggleFilterOpen={toggleFilterOpen}
                search={search}
              />
            ) : null}
          </MuiGrid>
          <MuiGrid md={12} xs={12} paddingX={0}>
            {showHighlights && filters.location ? (
              <VendorListHighlights
                location={filters.location}
                search={search}
                highlightCategoryData={
                  highlightsData.vendorHighlightsForLocation.highlightCategories
                }
              />
            ) : null}
            <VendorsList
              filters={filters}
              called={called}
              defaultSearch={defaultSearch}
              vendors={vendors}
              pageInfo={pageInfo}
              search={search}
              searching={searching}
            />
          </MuiGrid>
        </MuiGrid>
      </MainWrapper>
    </Container>
  )
}

export function getStaticProps() {
  return {
    props: {},
  }
}

export function getStaticPaths() {
  return {
    paths: getDomainLangPaths(),
    fallback: 'blocking',
  }
}

const Component: React.FC<AppProps> = withGm(Vendors)
Component.displayName = 'GM'

export default Component
