import { Button, Grid, Typography } from '@mui/material'
import { VendorSearchState } from '@/components/page-specific/gm/components/states/vendor-list'
import LoadingIndicator from '@/components/LoadingIndicator'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import VendorCardMui from '../vendor-card-mui'

const Center = styled.div`
  text-align: center;
  margin: 16px auto;
`

type Props = {
  pageInfo: VendorSearchState['pageInfo']
  vendors: VendorSearchState['vendors']
  called: boolean
  searching: boolean
  filters: VendorSearchState['filters']
  search: VendorSearchState['search']
  defaultSearch: VendorSearchState['defaultSearch']
}

const VendorsList: React.FC<Props> = ({
  pageInfo,
  vendors,
  searching,
  filters,
  search,
  defaultSearch,
  called,
}) => {
  const results = Boolean(vendors.length)

  const selectedHighlight = filters.highlightsTags?.[0] ?? ''
  useEffect(defaultSearch, [])
  return (
    <>
      {(results || selectedHighlight) && (
        <>
          <Typography gutterBottom variant="h4">
            {selectedHighlight || 'All'} vendors ({pageInfo?.count} available)
            {selectedHighlight ? (
              <Button size="small" variant="text" onClick={() => search({ highlightsTags: [] })}>
                Back to all
              </Button>
            ) : null}
          </Typography>
          <Grid spacing={2} container alignItems="stretch" display="flex">
            {vendors.map((item, index) => (
              <Grid item key={`${item.id}-${index}`} xs={12} sm={12} md={6} lg={4} xl={4}>
                <VendorCardMui vendor={item} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {called && !searching && !results && (
        <Center>
          <Typography variant="body1">No results found</Typography>
        </Center>
      )}
      {searching && (
        <Center>
          <LoadingIndicator />
        </Center>
      )}
      {pageInfo?.hasMore && (
        <Center>
          <Button variant="contained" onClick={() => search({ offset: vendors.length })}>
            Show more
          </Button>
        </Center>
      )}
    </>
  )
}

export default VendorsList
