import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Link from '@/components/Link'
import { sortBy, truncate } from 'lodash'
import {
  useCreateOrUpdateMyFavouriteMutation,
  UserFavouriteType,
  VendorsGmQuery,
} from '@/generated/graphql'
import Image from 'next/image'
import React, { useState } from 'react'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import VendorRating from '../vendor-rating'
import { getImageUrl } from '../image'
import LocationDetails from './LocationDetails'
import { CheckCircle, Lock } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { useAuthState } from '../states/auth'
import { match } from 'ts-pattern'

export type Props = {
  vendor: NonNullable<VendorsGmQuery['vendors']['rows']>[number]
}

const VendorCardMui: React.FC<Props> = ({ vendor }) => {
  const authState = useAuthState()
  const theme = useTheme()
  const [isFavourite, setIsFavourite] = useState<boolean>(vendor.isMyFavourite || false)
  const [updateMyFavourite] = useCreateOrUpdateMyFavouriteMutation()
  const account = match(authState)
    .with({ type: 'authenticated' }, ({ user }) => user.account)
    .with({ type: 'unauthenticated' }, () => null)
    .with({ type: 'loading' }, () => null)
    .exhaustive()
  const vendorImage = sortBy(vendor.images || [], 'position')
  const truncatedDescription = truncate(vendor?.descriptionShort ?? '', {
    length: 192,
    separator: ' ',
  })

  const showMyFavourite = vendor.isMyFavourite === true || vendor.isMyFavourite === false

  const toggleFavourite = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsFavourite((prevState) => !prevState)
    updateMyFavourite({
      variables: {
        input: {
          favouriteId: parseInt(vendor.id, 10),
          favouriteType: UserFavouriteType.Supplier,
          isFavourite: !isFavourite,
        },
      },
    })
  }

  if (!vendor.permalink) return null

  return (
    <Link href={`/office-catering/vendors/${vendor.permalink}/`}>
      <Card
        sx={{
          position: 'relative',
          marginBottom: 3,
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CardMedia
          alt={vendor.companyName}
          component="img"
          height="225"
          image={
            vendorImage[0]?.secureUrl
              ? getImageUrl({ src: vendorImage[0]?.secureUrl, width: 500 })
              : ''
          }
          style={{
            objectFit: 'cover',
          }}
        />
        {account && vendor.isApprovedSupplier && (
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircle sx={{ marginRight: 0.5, fontSize: '0.9rem' }} />
                {`Approved Supplier for ${account?.name}`}
              </Box>
            }
            sx={{
              position: 'absolute',
              top: 5,
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: theme.palette.grey[900],
            }}
            color="primary"
            size="small"
          />
        )}
        {account && vendor.accountIdsWithPrivateItemAccess?.includes(Number(account.id)) && (
          <Chip
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Lock sx={{ marginRight: 0.5, fontSize: '0.9rem' }} />
                {`Includes Private Items for ${account?.name}`}
              </Box>
            }
            sx={{
              position: 'absolute',
              top: vendor.isApprovedSupplier ? 35 : 5,
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: theme.palette.primary.main,
            }}
            color="primary"
            size="small"
          />
        )}

        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            position: 'relative',
          }}
        >
          <Stack
            direction="row"
            flexWrap="nowrap"
            alignItems="center"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Box sx={{ flexGrow: 0 }}>
              {vendor.logo && (
                <Image
                  src={vendor?.logo}
                  width={48}
                  height={48}
                  alt={vendor.companyName}
                  style={{
                    zIndex: 1,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              )}
            </Box>
            <Box sx={{ flexGrow: 0, alignSelf: 'center' }}>
              <Typography variant="h6" minWidth="175px">
                {vendor.companyName}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
              {(vendor.rating || 0) >= 2 && (
                <VendorRating
                  justify="flex-end"
                  rating={vendor.rating || 0}
                  totalRatings={vendor.totalRatings || 0}
                />
              )}
            </Box>
          </Stack>
          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
          <LocationDetails vendor={vendor} />
          <Tooltip placement="top" title={vendor?.descriptionShort || ''}>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {truncatedDescription}
            </Typography>
          </Tooltip>
        </CardContent>
        <CardActions sx={{ padding: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {vendor.tags && (
              <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
                {vendor.tags.map((tag, index) => (
                  <Tooltip key={index} placement="top" title={`This is a ${tag?.label}`}>
                    <Chip
                      label={tag?.label?.replace(/ kitchen/i, '')}
                      color="secondary"
                      variant="outlined"
                      icon={<RestaurantMenuIcon />}
                      size="small"
                    />
                  </Tooltip>
                ))}
              </Stack>
            )}
            {showMyFavourite && (
              <Box
                sx={{
                  marginLeft: 'auto', // Ensures the IconButton is always pushed to the far right
                  alignSelf: 'flex-end', // Aligns the IconButton to the top of the first row
                }}
              >
                <IconButton color="default" aria-label="toggle favourite" onClick={toggleFavourite}>
                  {isFavourite === true ? (
                    <FavoriteIcon
                      sx={{
                        color: 'red', // Red fill
                        fontSize: '3rem', // Larger size
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      sx={{
                        fontSize: '3rem', // Larger size
                        color: '#f0f0f0',
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
        </CardActions>
      </Card>
    </Link>
  )
}

export default VendorCardMui
