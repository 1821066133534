import React from 'react'
import { Star } from '@mui/icons-material'
import { Stack, Tooltip, Typography } from '@mui/material'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

type Props = {
  rating: number
  totalRatings?: number
  justify?: string
}

const VendorRating: React.FC<Props> = ({ rating, totalRatings, justify = 'justify-end' }) => {
  const showFiveStarRating = useFeatureFlag(SPLITS.FIVE_STAR_RATINGS)
  const ratingLabel = Math.round((rating + Number.EPSILON) * 100) / 100 // 2 decimal places max
  return (
    <Stack
      flexWrap="nowrap"
      display="flex"
      justifyContent={justify}
      alignItems="center"
      spacing={0.5}
      direction="row"
    >
      <Star htmlColor="gold" fontSize="small" />{' '}
      <Typography noWrap variant="caption">
        {ratingLabel} / {showFiveStarRating ? 5 : 4}
      </Typography>
      {(totalRatings || 0) > 0 && (
        <Tooltip title={`${totalRatings} rating${(totalRatings || 0) > 1 ? 's' : ''}`}>
          <Typography noWrap color="text.secondary" variant="caption">
            ({totalRatings})
          </Typography>
        </Tooltip>
      )}
    </Stack>
  )
}

export default VendorRating
